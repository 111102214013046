import React from 'react';
import { JobModel } from './jobSlice';
import JobHeader from './JobHeader';
import ListBlock from '../shared/ListBlock';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

export function Job(job: JobModel) {
    const jobHeader = JobHeader(job);
    const jobResponsibilities = ListBlock("Responsibilities", job.responsibilities);
    const accomplishments = job.accomplishments.map((a) => a.description);
    const jobAccomplishments = ListBlock("Accomplishments", accomplishments);
    const skillTags = job.skills?.map((skill) => 
        <Grid item key={skill}><Chip color="primary" label={skill} /></Grid>
    );

    return (
        <Paper key={job.company.trim()} elevation={2} sx={{ p: "3vmin", pb:0}}>
            { jobHeader }
            <Grid container spacing={1} sx={{ mb: 2 }}>
                {skillTags}
            </Grid>
            {
                (job.responsibilities.length > 0 || job.accomplishments.length > 0 || job.details) && (
                    <>
                        <Divider />
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                <Typography></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    job.details && job.details !== "" && (
                                        <Typography variant="body1" sx={{ mb: 3 }}>{ job.details }</Typography>
                                    )
                                }
                                {
                                    job.responsibilities.length > 0 && (
                                        jobResponsibilities
                                    )
                                }
                                {
                                    job.accomplishments.length > 0 && (
                                        jobAccomplishments
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            }
            
        </Paper>
    )
}