import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks';
import { toggleTheme, isDarkMode, setNavAnchor, clearNavAnchor, selectNavAnchor } from './appSlice';
import icon from '../static/logo-white.svg';
import { pages } from '../App';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled } from '@mui/material/styles';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export function Header() {
    const darkMode = useAppSelector(isDarkMode);
    const navAnchor = useAppSelector(selectNavAnchor);
    const dispatch = useAppDispatch();
    const anchorElement = navAnchor ? document.getElementById(navAnchor) : null;
    const routes = pages.filter((p) => p.public);
    const trigger = useScrollTrigger();
    
    return (
        <>
            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar>
                    <Toolbar>
                        <Box>
                            <MuiLink component={RouterLink} to="/"><img alt="MS icon" src={icon} width="40vmin" /></MuiLink>
                        </Box>
                        <Typography variant="hb4" component="div" sx={{ ml: 3, display: { xs: "none", md: "flex" } }}>
                            Mickey Stringer
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}></Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                            id="navMenuButton"
                            size="large"
                            aria-label="link menu toggle"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => dispatch(setNavAnchor(e.currentTarget.id))}
                            color="inherit"
                            >
                            <MenuIcon />
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorElement}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElement)}
                            onClose={() => dispatch(clearNavAnchor())}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            >
                            {routes.map((route) => (
                                <MenuItem key={route.label} onClick={() => dispatch(clearNavAnchor())}>
                                    <MuiLink component={RouterLink} to={ route.slug } color="inherit" variant="h6" underline="none">
                                        {route.label}
                                    </MuiLink>
                                </MenuItem>
                            ))}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: 3 }}>
                            {routes.map((route) => (
                            <MuiLink
                                key={route.label}
                                onClick={() => dispatch(clearNavAnchor())}
                                sx={{ my: 2, color: 'white', ml: 2}}
                                variant="h6"
                                display="block"
                                component={RouterLink}
                                to={ route.slug }
                            >
                                { route.label }
                            </MuiLink>
                            ))}
                        </Box>
                        {
                            darkMode ?
                            <IconButton
                                size="large"
                                aria-label="toggle light mode"
                                onClick={(e) => dispatch(toggleTheme())}
                                color="inherit"
                            >
                                <LightModeIcon />
                            </IconButton>
                            :
                            <IconButton
                                size="large"
                                aria-label="toggle dark mode"
                                onClick={(e) => dispatch(toggleTheme())}
                                color="inherit"
                            >
                                <DarkModeIcon />
                            </IconButton>
                        }
                    </Toolbar>
                </AppBar>
            </Slide>
            <Offset />
        </>
    );
}
