import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export default function ListBlock(title: string, list: string[]) {

    return (
        <>
            <Typography variant="h5" component="h3">
                {title}
            </Typography>
            <Divider variant="middle" />
            <List>
            {
                list.map((item: string, i: number) =>
                    <ListItem key={i}>
                        <ListItemText primary={item} />
                    </ListItem>
                )
            }
            </List>
        </>
    )
}