import { ProjectModel } from "./projectSlice";

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Grid from "@mui/material/Grid";
import LanguageIcon from '@mui/icons-material/Language';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TrafficIcon from '@mui/icons-material/Traffic';
import Typography from '@mui/material/Typography';

export default function ProjectHeader(project: ProjectModel, mediumView: boolean, smallView: boolean, darkMode: boolean) {
    const imgUriLight = "data:image/svg+xml;base64," + project.image.lightMode;
    const imgUriDark = "data:image/svg+xml;base64," + project.image.darkMode;
    const imgUri = darkMode ? imgUriDark : imgUriLight;
    const headerJustify = smallView ? "flex-start" : "center";
    const imageSize = mediumView ? "150px" : "120px";
    const spacing = smallView ? 4 : 2;

    return (
        <>
            <Grid container spacing={spacing} sx= {{ mb: 2, justifyContent: headerJustify }}>
                <Grid item sm={"auto"} >
                    <Avatar 
                    alt="Mickey Stringer" 
                    src={imgUri} 
                    variant="square" 
                    sx={{ width: imageSize, height: imageSize }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={"auto"}>
                    <Typography variant="h4" component="h2">
                        {project.name }
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TrafficIcon />
                            </ListItemIcon>
                            <Chip label={project.status} color={project.status} />
                        </ListItem>
                        {
                            project.url !== "" && project.status === "Published" && (
                                <ListItem>
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <MuiLink href={ project.url } color="inherit" target="_blank" rel="noreferrer">{ project.url }</MuiLink>
                                </ListItem>
                            )
                        }
                    </List>
                </Grid>
            </Grid>
        </>
    )
}