import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AppState {
    darkMode: boolean,
    navAnchor: string | null,
    showSplash: boolean
}

const initialState: AppState = {
    darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
    navAnchor: null,
    showSplash: true
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSplash: (state) => {
            state.showSplash = false;
        },
        toggleTheme: (state) => {
            state.darkMode = !state.darkMode;
        },
        setNavAnchor: (state, action: PayloadAction<string>) => {
            state.navAnchor = action.payload;
        },
        clearNavAnchor: (state) => {
            state.navAnchor = null;
        }
    }
})

export const { hideSplash, toggleTheme, setNavAnchor, clearNavAnchor } = appSlice.actions;

export const selectShowSplash = (state: RootState) => state.app.showSplash;
export const isDarkMode = (state: RootState) => state.app.darkMode;
export const selectNavAnchor = (state: RootState) => state.app.navAnchor;

export default appSlice.reducer;