import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        Published: Palette['primary'];
        Build: Palette['primary'];
        Planning: Palette['primary'];
    }
    interface PaletteOptions {
        Published: PaletteOptions['primary'];
        Build: PaletteOptions['primary'];
        Planning: PaletteOptions['primary'];
    }
    
    interface TypographyVariants {
    hb1: React.CSSProperties;
    hb3: React.CSSProperties;
    hb4: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
    hb1?: React.CSSProperties;
    hb3?: React.CSSProperties;
    hb4?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
    hb1: true;
    hb3: true;
    hb4: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        Published: true;
        Build: true;
        Planning: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        Published: true;
        Build: true;
        Planning: true;
    }
}

const baseTheme = createTheme({
    components: {
        // Name of the component
        MuiAccordionSummary: {
            styleOverrides: {
                // Name of the slot
                content: {
                // Some CSS
                flexGrow: 0,
                },
            },
        }
    },
    typography: {
        h1: {
            fontFamily: "Squada One"
        },
        h2: {
            fontFamily: "Squada One"
        },
        h3: {
            fontFamily: "Squada One"
        },
        h4: {
            fontFamily: "Squada One"
        },
        h5: {
            fontFamily: "Squada One"
        },
        h6: {
            fontFamily: "Squada One"
        },
        hb1: {
            fontFamily: "Bangers",
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em"
        },
        hb3: {
            fontFamily: "Bangers",
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em"
        },
        hb4: {
            fontFamily: "Bangers",
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em"
        }
    }
})

export const lightTheme = createTheme({
    ...baseTheme,
    palette: {
        primary: {
            main: '#00074c',
            contrastText: "#fff"
        },
        secondary: {
            main: '#03fcba'
        },
        Planning: {
            main: "#fc033d",
            contrastText: "#fff"
        },
        Build: {
            main: "#1c74c7",
            contrastText: "#fff"
        },
        Published: {
            main: "#03fc7f",
            contrastText: "#000"
        }
    },
    
});

export const darkTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'dark',
        Planning: {
            main: "#fc033d",
            contrastText: "#fff"
        },
        Build: {
            main: "#1c74c7",
            contrastText: "#fff"
        },
        Published: {
            main: "#03fc7f",
            contrastText: "#000"
        }
    },
});