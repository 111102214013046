import { JobModel } from "./jobSlice";

import ApartmentIcon from '@mui/icons-material/Apartment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';

export default function JobHeader(job: JobModel) {
    const months = [
        "January", "February", "March", "April", 
        "May", "June", "July", "August", 
        "September", "October", "November", "December"
    ];
    const startDate = new Date(job.startDate);
    const startYear= startDate.toISOString().split("-")[0];
    const startMonth = months[startDate.getMonth()];
    const endDate = new Date(job.endDate);
    const endYear = endDate.toISOString().split("-")[0];
    const endMonth = months[endDate.getMonth()];

    return (
        <>
            <Typography variant="h4" component="h2">
                {job.role}
            </Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={job.company} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary={job.city + ", " + job.state} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CalendarTodayIcon />
                    </ListItemIcon>
                    <ListItemText primary={startMonth + " " + startYear + (endMonth ? " - " + endMonth + " " + endYear : "")} />
                </ListItem>
            </List>
        </>
    )
}