import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchContent, ContentModel } from '../shared/contentAPI';


export interface AboutState {
    content: ContentModel,
    status: 'idle' | 'loading' | 'failed',
}

const initialState: AboutState = {
    content: {
        header: "",
        body: ""
    },
    status: 'idle'
};

export const getAboutContentAsync = createAsyncThunk(
    'about/fetchContent',
    async () => {
    const response = await fetchContent("about");
    // The value we return becomes the `fulfilled` action payload
    return response;
    }
);

export const aboutSlice = createSlice({
    name: 'about',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
        .addCase(getAboutContentAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getAboutContentAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.content = action.payload;
        });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAboutContent = (state: RootState) => state.about.content;

export default aboutSlice.reducer;
