import React, { useEffect } from 'react';
import { selectAboutContent } from './features/about/aboutSlice';
import { hideSplash, isDarkMode, selectShowSplash } from './app/appSlice';
import { ContentModel } from './features/shared/contentAPI';
import { useAppSelector } from './app/hooks';
import { selectWorkContent } from './features/jobs/jobSlice';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { store } from './app/store';

import { Admin } from './features/admin/Admin';
import { About } from './features/about/About';
import { Header } from './app/Header';
import { Jobs } from './features/jobs/Jobs';

import './App.css';
import Box from '@mui/material/Box';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from '@mui/material/Grid';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { lightTheme, darkTheme } from './app/Themes';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Projects } from './features/projects/Projects';
import { selectProjectContent } from './features/projects/projectSlice';

interface PageModel {
    label: string,
    component: JSX.Element,
    slug: string,
    public: boolean
}

export const pages: PageModel[] = [
    {
        label: "Home",
        component: <About />,
        slug: "/",
        public: true
    },
    {
        label: "Work History",
        component: <Jobs />,
        slug: "/Work%20History",
        public: true
    },
    {
        label: "Projects",
        component: <Projects />,
        slug: "/Projects",
        public: true
    },
    {
        label: "Admin",
        component: <Admin />,
        slug: "/admin",
        public: false
    }
]

function contentReady(path: string, about: ContentModel, jobs: ContentModel, projects: ContentModel): boolean {
    
    switch (path) {
        case "/":
            if (!about.header || about.header === "") {
                return false;
            }
            return true;
        case "/work%20history":
            if (!jobs.header || jobs.header === "") {
                return false;
            }
            return true;
        case "/projects":
            if (!projects.header || projects.header === "") {
                return false;
            }
            return true;
        default:
            return false;
    }

}

function delaySplash() {
    setTimeout(() => {
        store.dispatch(hideSplash());
    }, 4000)
}

function App() {
    const darkMode: boolean = useAppSelector(isDarkMode);
    const aboutContent = useAppSelector(selectAboutContent);
    const jobsContent = useAppSelector(selectWorkContent);
    const projectsContent = useAppSelector(selectProjectContent);
    const path = window.location.pathname.toLowerCase();
    const ready = contentReady(path, aboutContent, jobsContent, projectsContent);
    const showSplash = useAppSelector(selectShowSplash);

    useEffect(() => {
        delaySplash();
    }, [])

    return (
        <Router>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <CssBaseline />
                {
                    (!ready || showSplash) ? (
                        <Splash />
                    ) : (
                        <>
                            <Header />
                            <Routes>
                                {
                                    pages.map((page) => (
                                        <Route key={ page.label } path={ page.slug } element={ page.component }/>
                                    ))
                                }
                            </Routes>
                        </>
                    )
                }
                
            </ThemeProvider>
        </Router>
    );
}

function Splash() {
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.up("sm"))
    
    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', height: "100vh"}}>
            <Grid container spacing={1} alignItems="center" alignContent="center" justifyContent="center" className="gradient">
                <Grid item xs={12} sm={10} md={8} lg={6} sx={{ textAlign: "center"}}>
                    <div className="typewriter" >
                        {
                            tablet ? (
                                <h1 style={{ ...theme.typography.hb1 }}>Mickey Stringer</h1>
                            ) : (
                                <h3 style={{ ...theme.typography.hb3 }}>Mickey Stringer</h3>
                            )
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default App;
