import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchJobs } from './jobsAPI';
import { fetchContent, ContentModel } from '../shared/contentAPI';

export interface AccomplishmentModel {
    description: string,
    skills: string[]
}

export interface JobModel {
    company: string,
    city: string,
    state: string,
    role: string,
    otherRoles: string[],
    categories: string[],
    skills: string[],
    details: string,
    responsibilities: string[],
    accomplishments: AccomplishmentModel[],
    startDate: string,
    endDate: string
}

export interface JobState {
    jobs: JobModel[],
    jobStatus: 'idle' | 'loading' | 'failed',
    content: ContentModel,
    contentStatus: 'idle' | 'loading' | 'failed',
}

const initialState: JobState = {
    jobs: [],
    jobStatus: 'idle',
    content: {
        header: "",
        body: ""
    },
    contentStatus: "idle"
};

export const getJobsAsync = createAsyncThunk(
    'jobs/fetchJobs',
    async () => {
    const response = await fetchJobs();
    // The value we return becomes the `fulfilled` action payload
    return response;
    }
);

export const getWorkContentAsync = createAsyncThunk(
    'jobs/fetchContent',
    async () => {
    const response = await fetchContent("work");
    // The value we return becomes the `fulfilled` action payload
    return response;
    }
);

export const jobSlice = createSlice({
    name: 'jobs',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
        .addCase(getJobsAsync.pending, (state) => {
            state.jobStatus = 'loading';
        })
        .addCase(getJobsAsync.fulfilled, (state, action) => {
            state.jobStatus = 'idle';
            state.jobs = action.payload;
        })
        .addCase(getWorkContentAsync.pending, (state) => {
            state.contentStatus = 'loading';
        })
        .addCase(getWorkContentAsync.fulfilled, (state, action) => {
            state.contentStatus = 'idle';
            state.content = action.payload;
        });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectJobs = (state: RootState) => state.jobs.jobs;
export const selectWorkContent = (state: RootState) => state.jobs.content;

export default jobSlice.reducer;
