import { ProjectModel } from "./projectSlice";
import ProjectHeader from "./ProjectHeader";
import ListBlock from "../shared/ListBlock";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'


export function Project(project: ProjectModel, mediumView: boolean, smallView: boolean, darkMode: boolean) {
    const header = ProjectHeader(project, mediumView, smallView, darkMode);
    const stack = ListBlock("Stack", project.stack);

    return (
        <Paper key={project.name.trim()} elevation={2} sx={{ p: "3vmin", pb:0}}>
            {header}
            <Divider />
            <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                    <Typography></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" sx={{ mb: 3 }}>{ project.narrative }</Typography>
                    {stack}
                    {
                        project.hosting && project.hosting !== "" && (
                            <>
                                <Typography variant="h5" component="h3">
                                    Hosting
                                </Typography>
                                <Divider variant="middle" />
                                <Typography variant="body1" sx={{ p: 2 }}>
                                    {project.hosting}
                                </Typography>
                            </>
                        )
                    }
                    
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}