import { useAppSelector } from '../../app/hooks';
import { selectAboutContent } from './aboutSlice';
import HTMLReactParser from 'html-react-parser';

import avatar from '../../static/me.jpg';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

export function About() {
    const content = useAppSelector(selectAboutContent);
    const contentHtml = HTMLReactParser(content.body);

    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.up("sm"))
    const sizes = tablet ? "h1" : "h2";

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p:"2vmin", mb: 5 }}>
            <Grid container spacing={1} sx={{justifyContent: 'center'}}>
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <Paper elevation={3} sx={{ padding: "1em" }} >
                        {
                            content && content.body !== "" ? (
                                <Grid container sx={{ justifyContent: "center" }}>
                                    <Stack>
                                        <Avatar alt="Mickey Stringer" src={avatar} sx={{ width: "150px", height: "150px", alignSelf: "center" }}/>
                                        <Typography variant={sizes} component="h1">
                                            {content.header}
                                        </Typography>
                                    </Stack>
                                    <div style={{ padding: "1vmin" }}>
                                        {contentHtml}
                                    </div>
                                </Grid>
                            ) : (
                                <>
                                    <Stack spacing={3} >
                                        <Skeleton variant="circular" width={150} height={150} sx={{ alignSelf: "center" }}/>
                                        <Skeleton variant="rectangular" width={240} height={80} sx={{ alignSelf: "center" }} />
                                    </Stack>
                                    <Stack sx={{ mt: 5}}>
                                        <Skeleton variant="text" width={"90%"} sx={{ alignSelf: "center" }} />
                                        <Skeleton variant="text" width={"90%"} sx={{ alignSelf: "center" }} />
                                        <Skeleton variant="text" width={"90%"} sx={{ alignSelf: "center" }} />
                                        <Skeleton variant="text" width={"90%"} sx={{ alignSelf: "center" }} />
                                        <Skeleton variant="text" width={"90%"} sx={{ alignSelf: "center" }} />
                                        <Skeleton variant="text" width={"90%"} sx={{ alignSelf: "center" }} />
                                    </Stack>
                                </>
                            )
                        }
                        
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}