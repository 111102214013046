import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import jobReducer, { getJobsAsync, getWorkContentAsync } from '../features/jobs/jobSlice';
import projectReducer, { getProjectsAsync, getProjectContentAsync } from '../features/projects/projectSlice';
import appReducer from './appSlice';
import aboutReducer, { getAboutContentAsync } from '../features/about/aboutSlice';

export const store = configureStore({
    reducer: {
        about: aboutReducer,
        app: appReducer,
        jobs: jobReducer,
        projects: projectReducer
    },
});

store.dispatch(getAboutContentAsync());
store.dispatch(getWorkContentAsync());
store.dispatch(getJobsAsync());
store.dispatch(getProjectsAsync());
store.dispatch(getProjectContentAsync());

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
