import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ContentModel } from '../shared/contentAPI';
import { fetchProjects } from './projectsAPI';
import { fetchContent } from '../shared/contentAPI';

export interface ProjectModel {
    name: string,
    narrative: string,
    stack: string[],
    hosting?: string,
    status: "Published" | "Build" | "Planning",
    url?: string,
    image: {
        lightMode: string,
        darkMode: string
    }
}

export interface ProjectState {
    projects: ProjectModel[],
    projectStatus: 'idle' | 'loading' | 'failed',
    content: ContentModel,
    contentStatus: 'idle' | 'loading' | 'failed',
}

const initialState: ProjectState = {
    projects: [],
    projectStatus: "idle",
    content: {
        header: "",
        body: ""
    },
    contentStatus: "idle"
}

export const getProjectsAsync = createAsyncThunk(
    'projects/fetchProjects',
    async () => {
    const response = await fetchProjects();
    // The value we return becomes the `fulfilled` action payload
    return response;
    }
);

export const getProjectContentAsync = createAsyncThunk(
    'projects/fetchContent',
    async () => {
    const response = await fetchContent("projects");
    // The value we return becomes the `fulfilled` action payload
    return response;
    }
);

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProjectsAsync.pending, (state) => {
            state.projectStatus = 'loading';
        })
        .addCase(getProjectsAsync.fulfilled, (state, action) => {
            state.projectStatus = 'idle';
            state.projects = action.payload;
        })
        .addCase(getProjectContentAsync.pending, (state) => {
            state.contentStatus = 'loading';
        })
        .addCase(getProjectContentAsync.fulfilled, (state, action) => {
            state.contentStatus = 'idle';
            state.content = action.payload;
        })
    }
})

export const selectProjects = (state: RootState) => state.projects.projects;
export const selectProjectContent = (state: RootState) => state.projects.content;

export default projectSlice.reducer;