import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectJobs, selectWorkContent } from './jobSlice';
import { Job } from './Job';
import HTMLReactParser from 'html-react-parser';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



export function Jobs() {
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.up("sm"))
    const sizes = tablet ? "h1" : "h2";

    const jobState = useAppSelector(selectJobs);
    const content = useAppSelector(selectWorkContent);
    const contentHtml = HTMLReactParser(content.body);
    
    const jobList = jobState.map((job) => 
        Job(job)
    );

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', p:"2vmin", mb: 5}}>
                <Grid container spacing={1} sx={{justifyContent: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                    {
                        (jobState.length < 1 || content.header === "") ? (
                            <Stack sx={{ mt: 3 }}>
                                <Skeleton variant="rectangular" width={"80%"} height={"4em"} />
                                <p></p>
                                <Skeleton variant="text" width={"80%"} />
                                <Skeleton variant="text" width={"80%"} />
                                <p></p>
                                <Paper elevation={3} sx={{ p: "3vmin"}}>
                                    <Skeleton variant="rectangular" width={"60%"} height={"2em"} />
                                    <Skeleton variant="text" width={"80%"} />
                                    <Skeleton variant="text" width={"80%"} />
                                    <Skeleton variant="text" width={"80%"} />
                                </Paper>
                                <Paper elevation={3} sx={{ p: "3vmin", mt: 3}}>
                                    <Skeleton variant="rectangular" width={"60%"} height={"2em"} />
                                    <Skeleton variant="text" width={"80%"} />
                                    <Skeleton variant="text" width={"80%"} />
                                    <Skeleton variant="text" width={"80%"} />
                                </Paper>
                            </Stack>
                        ) : (
                            <Stack spacing={2}>
                                <Typography variant={sizes} component="h1">
                                    {content.header}
                                </Typography>
                                <Divider />
                                <div style={{ padding: "1vmin" }}>
                                    {contentHtml}
                                </div>
                                {jobList}
                            </Stack>
                        )
                    }
                    </Grid>
                </Grid>
            </Box>
        
        </>
    )
}